<template>
  <div>
    <div class="d-lg-none" style="margin-right: -103px;
    ">
<div class="d-block my-4 btn-shim">
    <button  class=" button-shape-m d-block mx-auto parallelograms position-relative" style="height:43px;">
      <span class="skew-fixs"> CHANGE MY <b> USERNAME</b></span>
    </button>
    <button @click="$store.state.modalNick = true" class=" button-shape-cont-m d-block mx-auto parallelograms position-relative" style="height:43px;">
      <span class="skew-fixs"> CHANGE MY <b> USERNAME</b></span> <i></i>
    </button>
  </div>
    </div>
    <div class="d-none d-lg-block" style="margin-right: -85px;
    margin-top: -20px;">
      <div class="d-block my-4 btn-shim">
    <button  class=" button-shape-m d-block mx-auto parallelograms position-relative" style="height:43px;">
      <span class="skew-fixs"> CHANGE MY <b> USERNAME</b></span>
    </button>
    <button @click="$store.state.modalNick = true" class=" button-shape-cont-m d-block mx-auto parallelograms position-relative" style="height:43px;">
      <span class="skew-fixs"> CHANGE MY <b> USERNAME</b></span> <i></i>
    </button>
  </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.button-shape-m:disabled {
  background: #c7c7c7 !important;
  color:rgb(60, 60, 60) !important;
}
.button-shape-m {
  font-family: "Calibri" !important;
  background: #b99653;
  color: rgb(234, 234, 234);
    border: 1px solid #b99653;
    letter-spacing: 2px;
  /* box-shadow: 0px 13px 19.53px 1.47px rgb(0 0 0 / 13%); */
font-size: .9rem;
    padding: 0.1rem 6.4rem 0.1rem 2.4rem;
    border-radius: 7px 6px 7px 7px;
}
/* .button-shape-cont-m:hover{
} */
.button-shape-cont-m {
  box-shadow: 24px 10px 21px 0px rgb(0 0 0 / 35%);

  font-family: "Calibri" !important;
  border: 1px solid transparent;
  color: transparent;
  letter-spacing: 2px;
  border-radius: 7px 6px 7px 7px;
  margin-top: -43px;
  background: transparent;
  font-size: .9rem;
    padding: 0.1rem 6.4rem 0.1rem 2.4rem;
}


.parallelograms{
  transform: skew(-40deg);
}

.skew-fixs{
  display:inline-block;
  transform: skew(40deg);
}

  .button-shape-cont-m i {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.03) 1%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0.85) 50%, rgba(255,255,255,0.85) 70%, rgba(255,255,255,0.85) 71%, rgba(255,255,255,0) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(1%,rgba(255,255,255,0.03)), color-stop(30%,rgba(255,255,255,0.85)), color-stop(50%,rgba(255,255,255,0.85)), color-stop(70%,rgba(255,255,255,0.85)), color-stop(71%,rgba(255,255,255,0.85)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* IE10+ */
  background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* W3C */
  width: 20%;
  height: 100%;
  animation: move 2s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  -webkit-animation: move 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 1s;
  -moz-animation: move 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-delay: 1s;
  -ms-animation: move 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-delay: 1s;
  -o-animation: move 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-delay: 1s;
  }
  
  @keyframes move {
    0%  { left: 0; opacity: 0.2; }
    5% {opacity: 0.3}
    48% {opacity: 0.3}
    80% {opacity: 0.5}
    100% { left: 85%}
    }
  
  @-webkit-keyframes move {
0%  { left: 0; opacity: 0.2; }
    5% {opacity: 0.3}
    48% {opacity: 0.3}
    80% {opacity: 0.5}
    100% { left: 85%}
  }
  
  @-moz-keyframes move {
  0%  { left: 0; opacity: 0.2; }
    5% {opacity: 0.3}
    48% {opacity: 0.3}
    80% {opacity: 0.5}
    100% { left: 85%}
  }
  
  @-ms-keyframes move {
  0%  { left: 0; opacity: 0.2; }
    5% {opacity: 0.3}
    48% {opacity: 0.3}
    80% {opacity: 0.5}
    100% { left: 85%}
  }
  
  @-o-keyframes move {
  0%  { left: 0; opacity: 0.2; }
    5% {opacity: 0.3}
    48% {opacity: 0.3}
    80% {opacity: 0.5}
    100% { left: 85%}
  }












  .button-shape-ms:disabled {
  background: #c7c7c7 !important;
  color:rgb(60, 60, 60) !important;
}
.button-shape-ms {
  font-family: "Calibri" !important;
  background: #b99653;
  color: rgb(234, 234, 234);
    border: 1px solid #b99653;
    letter-spacing: 2px;
  box-shadow: 0px 13px 19.53px 1.47px rgb(0 0 0 / 13%);
font-size: .9rem;
    padding: 0.1rem 2.4rem 0.1rem 6.4rem;
    border-radius: 7px 6px 7px 7px;
}
.button-shape-cont-ms {
  font-family: "Calibri" !important;
  box-shadow: 24px 10px 21px 0px rgb(0 0 0 / 35%);
  border: 1px solid transparent;
  color: transparent;
  letter-spacing: 2px;
  border-radius: 7px 6px 7px 7px;
  margin-top: -43px;
  background: transparent;
  font-size: .9rem;
    padding: 0.1rem 2.4rem 0.1rem 6.4rem;
}


.parallelogramss{
  transform: skew(40deg);
}

.skew-fixss{
  display:inline-block;
  transform: skew(-40deg);
}

  .button-shape-cont-ms i {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.03) 1%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0.85) 50%, rgba(255,255,255,0.85) 70%, rgba(255,255,255,0.85) 71%, rgba(255,255,255,0) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(1%,rgba(255,255,255,0.03)), color-stop(30%,rgba(255,255,255,0.85)), color-stop(50%,rgba(255,255,255,0.85)), color-stop(70%,rgba(255,255,255,0.85)), color-stop(71%,rgba(255,255,255,0.85)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* IE10+ */
  background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* W3C */
  width: 20%;
  height: 100%;
  animation: move 2s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  -webkit-animation: move 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 1s;
  -moz-animation: move 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-delay: 1s;
  -ms-animation: move 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-delay: 1s;
  -o-animation: move 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-delay: 1s;
  }
  
  
</style>